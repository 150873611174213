
import VueTypes from 'vue-types';

import query from '@/gql/queries/sections/events.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    showAll: VueTypes.bool.def(false),
  },
  data() {
    return {
      showCurrent: true,
      events: [],
      pastEvents: [],
      noEvents: {},
    };
  },
  async fetch() {
    const { events, pastEvents, noEvents } = await this.$cms.query({
      query,
      variables: {
        site: this.$site,
        limit: this.showAll ? 100 : 3,
      },
    });

    this.events = events || [];
    this.noEvents = noEvents || {};
    this.pastEvents = pastEvents || [];
  },
};
