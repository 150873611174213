import { render, staticRenderFns } from "./EventsOverview.vue?vue&type=template&id=7b13bbd5&scoped=true"
import script from "./EventsOverview.vue?vue&type=script&lang=js"
export * from "./EventsOverview.vue?vue&type=script&lang=js"
import style0 from "./EventsOverview.vue?vue&type=style&index=0&id=7b13bbd5&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b13bbd5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/workspace/app/components/nav/Nav.vue').default,TextCard: require('/workspace/app/components/cards/TextCard.vue').default,EventCard: require('/workspace/app/components/cards/EventCard.vue').default,Container: require('/workspace/app/components/layout/Container.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
