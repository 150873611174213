
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    subtitle: VueTypes.string,
    title: VueTypes.string,
    body: VueTypes.string,
    href: VueTypes.string,
    to: VueTypes.string,
    buttonLabel: VueTypes.string,
  },
};
