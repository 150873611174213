
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    date: VueTypes.string,
    timeslot: VueTypes.arrayOf(
      VueTypes.objectOf({
        startTime: VueTypes.string,
        endTime: VueTypes.string,
      }),
    ),
    location: VueTypes.string,
    title: VueTypes.string,
    uri: VueTypes.string,
  },
};
